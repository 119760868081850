<template>
  <div class="main">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="productName">
          <span>企业系统</span>
        </div>
        <div class="slogan">
          <span>贯通任督二脉</span>
        </div>
        <div class="price">
          <span>RMB&nbsp;50,000.00&nbsp;起</span>
        </div>
        <div class="systemimg">
          <img src="img/ProductSystem.png" alt="" />
        </div>
        <div class="systemtxt">
          <span>量身定制，精雕细琢，只为您的企业经营能更上层楼。</span>
        </div>
        <div class="systemtxt">
          <span>选择阿半，选择专业。</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div class="priceName">
          <span>服务价目</span>
        </div>
        <div class="priceSlogan">
          <span>选择适合您的服务</span>
        </div>
        <div class="elementGroup">
          <div
            class="element"
            v-for="item in priceElementData"
            v-bind:key="item.key"
          >
            <div class="elementimg">
              <img :src="item.imgsrc" alt="" />
            </div>
            <div class="title">
              <span>{{ item.title }}</span>
            </div>
            <div class="price">
              <span>{{ item.price }}</span>
            </div>
            <div class="link">
              <span @click="openManual">了解更多</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="third">
      <div class="left"></div>
      <div class="center">
        <div class="iconGroup">
          <div
            class="element"
            v-for="item in informationizeData"
            v-bind:key="item.key"
          >
            <div class="iconbg">
              <div class="icon">
                <img :src="item.iconsrc" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>{{ item.txt }}</span>
            </div>
          </div>
        </div>
        <div class="iconGroup">
          <div
            class="element"
            v-for="item in baseInformationizeData"
            v-bind:key="item.key"
          >
            <div class="iconbg">
              <div class="icon">
                <img :src="item.iconsrc" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>{{ item.txt }}</span>
            </div>
          </div>
        </div>
        <div class="iconGroup">
          <div
            class="element"
            v-for="item in bussinessSystemData"
            v-bind:key="item.key"
          >
            <div class="iconbg">
              <div class="icon">
                <img :src="item.iconsrc" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>{{ item.txt }}</span>
            </div>
          </div>
        </div>
        <div class="iconGroup">
          <div
            class="element"
            v-for="item in expandableData"
            v-bind:key="item.key"
          >
            <div class="iconbg">
              <div class="icon">
                <img :src="item.iconsrc" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>{{ item.txt }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      priceElementData: [
        {
          key: 1,
          imgsrc: "img/Informationize.png",
          title: "信息化方案",
          price: "RMB 5,000.00 起",
          link: "",
        },
        {
          key: 2,
          imgsrc: "img/BaseInformationPlatform.png",
          title: "基础信息化平台",
          price: "RMB 15,000.00 起",
          link: "",
        },
        {
          key: 3,
          imgsrc: "img/BussinessSystem.png",
          title: "业务系统",
          price: "RMB 30,000.00 起",
          link: "",
        },
        {
          key: 4,
          imgsrc: "img/ExpandableService.png",
          title: "可拓展服务",
          price: "由业务复杂度决定",
          link: "",
        },
      ],
      informationizeData: [
        { key: 1, iconsrc: "img/SmallDeepIn.png", txt: "深度分析" },
        { key: 2, iconsrc: "img/SmallDeduce.png", txt: "信息化推演" },
        { key: 3, iconsrc: "img/SmallMatch.png", txt: "业务适配" },
      ],
      baseInformationizeData: [
        { key: 1, iconsrc: "img/SmallService.png", txt: "微服务架构" },
        { key: 2, iconsrc: "img/SmallGateWay.png", txt: "统一网关" },
        { key: 3, iconsrc: "img/SmallAuth.png", txt: "授权鉴权" },
        { key: 4, iconsrc: "img/SmallOrg.png", txt: "组织与用户" },
        { key: 5, iconsrc: "img/SmallCore.png", txt: "一次购买，处处使用" },
      ],
      bussinessSystemData: [
        { key: 1, iconsrc: "img/SmallCustomerService.png", txt: "客户服务" },
        { key: 2, iconsrc: "img/SmallSales.png", txt: "销售管理" },
        { key: 3, iconsrc: "img/SmallSalary.png", txt: "薪酬绩效" },
        { key: 4, iconsrc: "img/SmallIndivid.png", txt: "定制服务" },
        { key: 5, iconsrc: "img/SmallService.png", txt: "微服务架构" },
        { key: 6, iconsrc: "img/SmallDiscount.png", txt: "享受优惠" },
      ],
      expandableData: [
        { key: 1, iconsrc: "img/SmallDesign.png", txt: "优雅设计+" },
        { key: 2, iconsrc: "img/SmallMobile.png", txt: "移动化" },
        { key: 3, iconsrc: "img/SmallDiscount.png", txt: "享受优惠" },
      ],
    };
  },
  methods: {
    openManual() {
      this.$router.push({
        path: "/manual",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .first {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      height: 750px;
      .productName {
        height: 30px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 18px;
        }
      }
      .slogan {
        display: flex;
        height: 60px;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .price {
        display: flex;
        height: 30px;
        justify-content: center;
        align-items: center;
        span {
          font-size: 18px;
          color: #333333;
        }
      }
      .systemimg {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        margin-bottom: 25px;
        img {
          border-radius: 3px;
          box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.5);
        }
      }
      .systemtxt {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .second {
    display: flex;
    background-color: #f2f2f2;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      background-color: #f2f2f2;
      .priceName {
        margin-top: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 18px;
        }
      }
      .priceSlogan {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .elementGroup {
        display: flex;
        justify-content: center;
        .element {
          margin: 60px 15px 0 15px;
          height: 300px;
          border-bottom: solid 1px #cccccc;
          .elementimg {
            width: 212px;
            height: 167px;
            background-color: #ffffff;
            border: solid 1px #333333;
            border-radius: 3px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .title {
          margin-top: 10px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 20px;
            font-weight: 500;
          }
        }
        .price {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          span {
            font-size: 16px;
            color: #333333;
          }
        }
        .link {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          span {
            font-size: 12px;
            color: #3366ff;
            cursor: pointer;
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .third {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      min-width: 980px;
      width: 980px;
      display: flex;
      justify-content: center;
      .iconGroup {
        margin: 35px 15px 0px 15px;
        .element {
          width: 212px;
          margin-bottom: 35px;
          .iconbg {
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
              width: 48px;
              height: 44px;
              border-radius: 3px;
              background-color: #333333;
              box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
            }
          }
          .txt {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            span {
              font-size: 13px;
            }
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
}
</style>
