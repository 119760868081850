<template>
  <div class="main">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="element">
          <div class="elementimg">
            <img src="img/GentalDesign.png" alt="" />
          </div>
          <div class="title">
            <span>优雅设计+</span>
          </div>
          <div class="price">
            <span>由业务复杂度决定</span>
          </div>
          <div class="link">
            <span @click="openManual">了解更多</span>
          </div>
        </div>
        <div class="element">
          <div class="elementimg">
            <img src="img/PageMake.png" alt="" />
          </div>
          <div class="title">
            <span>页面制作+</span>
          </div>
          <div class="price">
            <span>由业务复杂度决定</span>
          </div>
          <div class="link">
            <span @click="openManual">了解更多</span>
          </div>
        </div>
        <div class="element">
          <div class="elementimg">
            <img src="img/CrossMinapp.png" alt="" />
          </div>
          <div class="title">
            <span>小程序互通</span>
          </div>
          <div class="price">
            <span>由业务复杂度决定</span>
          </div>
          <div class="link">
            <span @click="openManual">了解更多</span>
          </div>
        </div>
        <div class="element">
          <div class="elementimg">
            <img src="img/MultiOperation.png" alt="" />
          </div>
          <div class="title">
            <span>综合运营后台</span>
          </div>
          <div class="price">
            <span>由业务复杂度决定</span>
          </div>
          <div class="link">
            <span @click="openManual">了解更多</span>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div class="elementGroup">
          <div
            class="element"
            v-for="item in gentalDesignData"
            v-bind:key="item.key"
          >
            <div class="iconbg">
              <div class="icon">
                <img :src="item.iconsrc" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>{{ item.txt }}</span>
            </div>
          </div>
        </div>
        <div class="elementGroup">
          <div
            class="element"
            v-for="item in pageMakeData"
            v-bind:key="item.key"
          >
            <div class="iconbg">
              <div class="icon">
                <img :src="item.iconsrc" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>{{ item.txt }}</span>
            </div>
          </div>
        </div>
        <div class="elementGroup">
          <div
            class="element"
            v-for="item in crossMinappData"
            v-bind:key="item.key"
          >
            <div class="iconbg">
              <div class="icon">
                <img :src="item.iconsrc" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>{{ item.txt }}</span>
            </div>
          </div>
        </div>
        <div class="elementGroup">
          <div
            class="element"
            v-for="item in operationData"
            v-bind:key="item.key"
          >
            <div class="iconbg">
              <div class="icon">
                <img :src="item.iconsrc" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>{{ item.txt }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gentalDesignData: [
        { key: 1, iconsrc: "img/SmallDesign.png", txt: "优雅设计" },
        { key: 2, iconsrc: "img/SmallFormat.png", txt: "全新版式" },
        { key: 3, iconsrc: "img/SmallStruct.png", txt: "页面结构不限" },
        { key: 4, iconsrc: "img/SmallDiscount.png", txt: "享受优惠" },
      ],
      pageMakeData: [
        { key: 1, iconsrc: "img/SmallVue.png", txt: "Vue.js" },
        { key: 2, iconsrc: "img/SmallCode.png", txt: "交付源代码" },
        { key: 3, iconsrc: "img/SmallIndivid.png", txt: "定制业务" },
        { key: 4, iconsrc: "img/SmallResolution.png", txt: "整体解决方案" },
        { key: 5, iconsrc: "img/SmallDiscount.png", txt: "享受优惠" },
      ],
      crossMinappData: [
        { key: 1, iconsrc: "img/SmallCrossContent.png", txt: "资讯互通" },
        { key: 2, iconsrc: "img/SmallCrossProcess.png", txt: "业务互通" },
        { key: 3, iconsrc: "img/SmallAllInOne.png", txt: "一站式管理" },
        { key: 4, iconsrc: "img/SmallDiscount.png", txt: "享受优惠" },
      ],
      operationData: [
        { key: 1, iconsrc: "img/SmallContent.png", txt: "资讯后台" },
        { key: 2, iconsrc: "img/SmallMinappOperate.png", txt: "小程序运营" },
        { key: 3, iconsrc: "img/SmallService.png", txt: "微服务架构" },
        { key: 4, iconsrc: "img/SmallIndivid.png", txt: "定制业务" },
        { key: 5, iconsrc: "img/SmallDiscount.png", txt: "享受优惠" },
      ],
    };
  },
  methods: {
    openManual() {
      this.$router.push({
        path: "/manual",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .first {
    display: flex;
    background-color: #f2f2f2;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      display: flex;
      justify-content: center;
      background-color: #f2f2f2;
      .element {
        margin: 40px 15px 0px 15px;
        height: 300px;
        border-bottom: solid 1px #cccccc;
        .elementimg {
          width: 212px;
          height: 167px;
          background-color: #ffffff;
          border: solid 1px #333333;
          border-radius: 3px;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          margin-top: 10px;
          span {
            font-size: 20px;
            font-weight: 500;
          }
        }
        .price {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          span {
            font-size: 16px;
            color: #333333;
          }
        }
        .link {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          span {
            color: #3366ff;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }

  .second {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      display: flex;
      justify-content: center;
      .elementGroup {
        margin: 35px 15px 0px 15px;
        .element {
          width: 212px;
          margin-bottom: 35px;
          .iconbg {
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
              width: 48px;
              height: 44px;
              border-radius: 3px;
              background-color: #333333;
              box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
            }
          }
          .txt {
            display: flex;
            height: 36px;
            justify-content: center;
            align-items: center;
            span {
              font-size: 13px;
            }
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
}
</style>
