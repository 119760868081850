<template>
  <div class="main">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="element">
          <div class="elementimg">
            <img src="img/GentalDesign.png" alt="" />
          </div>
          <div class="title">
            <span>优雅设计</span>
          </div>
          <div class="price">
            <span>RMB&nbsp;1,800.00</span>
          </div>
          <div class="link">
            <span @click="openManual">了解更多</span>
          </div>
        </div>
        <div class="element">
          <div class="elementimg">
            <img src="img/PageMake.png" alt="" />
          </div>
          <div class="title">
            <span>页面制作</span>
          </div>
          <div class="price">
            <span>RMB&nbsp;6,000.00</span>
          </div>
          <div class="link">
            <span @click="openManual">了解更多</span>
          </div>
        </div>
        <div class="element">
          <div class="elementimg">
            <img src="img/InfoManage.png" alt="" />
          </div>
          <div class="title">
            <span>资讯后台</span>
          </div>
          <div class="price">
            <span>RMB&nbsp;2,000.00</span>
          </div>
          <div class="link">
            <span @click="openManual">了解更多</span>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div class="elementGroup">
          <div class="element">
            <div class="iconbg">
              <div class="icon">
                <img src="img/SmallDesign.png" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>优雅设计</span>
            </div>
          </div>
          <div class="element">
            <div class="iconbg">
              <div class="icon">
                <img src="img/SmallFormat.png" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>全新版式</span>
            </div>
          </div>
          <div class="element">
            <div class="iconbg">
              <div class="icon">
                <img src="img/SmallStruct.png" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>5&nbsp;种页面结构</span>
            </div>
          </div>
        </div>
        <div class="elementGroup">
          <div class="element">
            <div class="iconbg">
              <div class="icon">
                <img src="img/SmallVue.png" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>Vue.js</span>
            </div>
          </div>
          <div class="element">
            <div class="iconbg">
              <div class="icon">
                <img src="img/SmallCode.png" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>交付源代码</span>
            </div>
          </div>
        </div>
        <div class="elementGroup">
          <div class="element">
            <div class="iconbg">
              <div class="icon">
                <img src="img/SmallContent.png" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>文章管理</span>
            </div>
          </div>
          <div class="element">
            <div class="iconbg">
              <div class="icon">
                <img src="img/SmallService.png" alt="" />
              </div>
            </div>
            <div class="txt">
              <span>微服务架构</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openManual() {
      this.$router.push({
        path: "/manual",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .first {
    display: flex;
    background-color: #f2f2f2;
    .left {
      flex: auto;
    }
    .center {
      background-color: #f2f2f2;
      width: 980px;
      min-width: 980px;
      display: flex;
      justify-content: center;
      .element {
        margin: 40px 15px 0px 15px;
        height: 300px;
        border-bottom: solid 1px #cccccc;
        .elementimg {
          width: 276px;
          height: 167px;
          background-color: #ffffff;
          border: solid 1px #333333;
          border-radius: 3px;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
          display: flex;
          justify-content: center;
        }
        .title {
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          span {
            font-size: 20px;
            font-weight: 500;
          }
        }
        .price {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          span {
            font-size: 16px;
            color: #333333;
          }
        }
        .link {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          span {
            color: #3366ff;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .second {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      display: flex;
      justify-content: center;
      .elementGroup {
        margin: 35px 15px 0px 15px;
        .element {
          margin-bottom: 35px;
          width: 276px;
          .iconbg {
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
              width: 48px;
              height: 44px;
              background-color: #333333;
              border-radius: 3px;
              box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
            }
          }
          .txt {
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 13px;
            }
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
}
</style>
