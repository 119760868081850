import Vue from "vue";
import VueRouter from "vue-router";

import App from "../App.vue";
import Frame from "../views/Frame.vue";
import Home from "../views/Home.vue";
import Product from "../views/Product.vue";
import Customer from "../views/Customer.vue";
import Service from "../views/Service.vue";
import Motel from "../views/Motel.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import Manual from "../views/Manual.vue";

import Website from "../components/Website.vue";
import BrandPromotion from "../components/BrandPromotion.vue";
import InfoPortal from "../components/InfoPortal.vue";

import Minapp from "../components/Minapp.vue";
import InfoPlatform from "../components/InfoPlatform.vue";
import BussinessPlatform from "../components/BussinessPlatform.vue";

import System from "../components/System.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: App,
    children: [
      {
        path: "",
        component: Frame,
        redirect: "home",
        children: [
          {
            path: "home",
            component: Home,
          },
          {
            path: "product",
            component: Product,
            children: [
              {
                path: "",
                components: {
                  website: Website,
                  minapp: Minapp,
                  system: System,
                },
                children: [
                  {
                    path: "",
                    components: {
                      brandPromotion: BrandPromotion,
                      infoPortal: InfoPortal,
                      infoPlatform: InfoPlatform,
                      bussinessPlatform: BussinessPlatform,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "customer",
            component: Customer,
          },
          {
            path: "service",
            component: Service,
          },
          {
            path: "motel",
            component: Motel,
          },
          {
            path: "about",
            component: About,
          },
          {
            path: "contact",
            component: Contact,
          },
          {
            path: "manual",
            component: Manual,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next(from.path);
  } else {
    next();
  }
});

export default router;
