<template>
  <div class="main">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>所有的传奇都有起点</span>
        </div>
        <div class="minSlogan">
          <span>如果您不知道如何开始，请参看阿半为您准备的详细攻略。</span>
        </div>
        <div class="imgGroup">
          <div
            class="element"
            v-for="item in imgGroupData"
            v-bind:key="item.key"
            :style="item.background"
          >
            <div class="top"></div>
            <a :href="item.href">
              <div class="middle">
                <span>{{ item.txt }}</span>
              </div>
            </a>
          </div>
        </div>
        <div class="customertxt">
          <span>谋定而后动，妥当的规划是成功的保障。</span>
        </div>
        <div class="customertxt">
          <span>如果您有疑惑，请</span>
          <span class="link" @click="openContact">联系我们</span>
          <span>，让我们为您解答</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <span id="start"></span>
    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div class="title">
          <span>我想启动一个软件项目</span>
        </div>
        <div class="subTitle">
          <span>您可以通过以下任一渠道与我们取得联系</span>
        </div>
        <div class="startDetail">
          <span>在阿半软件留下您的需求，我们的客户经理将与您取得联系。</span>
        </div>
        <div class="startDetail">
          <span
            >通过第三方平台联系我们：
            <a href="http://bankezhan.taobao.com" target="_blank"
              >阿半软件淘宝旗舰店</a
            >。</span
          >
        </div>
        <div class="startDetail">
          <span>通过客服QQ与我们取得联系，阿半软件：2557923989。</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="third">
      <div class="left"></div>
      <div class="center">
        <div class="title">
          <span>阿半将帮助您完成项目的启动</span>
        </div>
        <div class="subTitle">
          <span>请您参照以下标准，监督我们的服务</span>
        </div>

        <table class="serviceTable">
          <tr class="header">
            <td class="hstep"><span>阶段</span></td>
            <td class="hcontent"><span>服务内容</span></td>
            <td class="hstandard"><span>服务标准</span></td>
          </tr>
          <tr
            v-for="item in startServiceData"
            v-bind:key="item.key"
            class="row"
          >
            <td class="rstep">
              <span>{{ item.step }}</span>
            </td>
            <td class="rcontent">
              <span>{{ item.content }}</span>
            </td>
            <td class="rstandard">
              <span>{{ item.standard }}</span>
            </td>
          </tr>
        </table>
        <div class="tabletxt">
          <span>以上服务均免费提供，不会产生任何额外费用。</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <span id="develop"></span>
    <div class="fourth">
      <div class="left"></div>
      <div class="center">
        <div class="title">
          <span>我想了解软件的开发过程</span>
        </div>
        <div class="subTitle">
          <span>阿半软件使用持续集成（CI）的方式开发软件，核心过程如下</span>
        </div>
        <table class="serviceTable">
          <tr class="header">
            <td class="hstep"><span>阶段</span></td>
            <td class="hcontent"><span>工作内容</span></td>
            <td class="htarget"><span>工作目的</span></td>
          </tr>
          <tr
            class="row"
            v-for="item in developServiceData"
            v-bind:key="item.key"
          >
            <td class="rstep">
              <span>{{ item.step }}</span>
            </td>
            <td class="rcontent">
              <span>{{ item.content }}</span>
            </td>
            <td class="rtarget">
              <span>{{ item.target }}</span>
            </td>
          </tr>
        </table>
        <div class="developtxt">
          <span
            >以上工作全程透明，您可以随时通过阿半软件为您提供的软件开发监督平台检查工作进度。</span
          >
        </div>
      </div>
      <div class="right"></div>
    </div>
    <span id="install"></span>
    <div class="fifth">
      <div class="left"></div>
      <div class="center">
        <div class="title">
          <span>开发完成后如何使用</span>
        </div>
        <div class="subTitle">
          <span>阿半软件的所有产品均为自动容器化部署，安装即用</span>
        </div>
        <div
          class="installGuide"
          v-for="item in installGuideData"
          v-bind:key="item.key"
        >
          <span>{{ item.content }}</span>
        </div>
        <div class="installtxt">
          <span>您可以选择自行安装或阿半软件为您免费提供</span>
          <span class="link" @click="openManual">自动化部署服务</span>
          <span>。</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgGroupData: [
        {
          key: 1,
          background: { background: "url('img/ServiceStart.png')" },
          href: "#start",
          txt: "我想启动一个软件项目",
        },
        {
          key: 2,
          background: { background: "url('img/ServiceDevelopment.png')" },
          href: "#develop",
          txt: "我想了解软件的开发过程",
        },
        {
          key: 3,
          background: { background: "url('img/ServiceInstall.png')" },
          href: "#install",
          txt: "开发完成后如何使用",
        },
      ],
      startServiceData: [
        {
          key: 1,
          step: "需求探讨",
          content: "听取您的需求，了解您的业务。",
          standard: "为您提供《业务需求初探案》，清晰描述业务与系统融合方案。",
        },
        {
          key: 2,
          step: "商务洽谈",
          content: "根据您确定的项目方案，计算项目实施费用。",
          standard: "为您提供《项目建设与报价案》，报价将精确至功能点。",
        },
        {
          key: 3,
          step: "合同签署",
          content: "根据您选择的建设方案，协助您签订项目合同。",
          standard:
            "为您提供《项目开发计划》，确定交付时间与费用，大部分费用将在验收后支付，提供增值税发票。",
        },
        {
          key: 4,
          step: "项目启动",
          content: "正式启动项目，为您部署项目开发监控平台。",
          standard:
            "启动当日阿半将正式通知您，届时您即可随时登录监控平台，开始监控开发进度直至项目结束。",
        },
      ],
      developServiceData: [
        {
          key: 1,
          step: "功能分解",
          content: "对所有功能分解归类，之后根据功能性质进行分层。",
          target: "理清功能的分布，为启动持续集成开发做准备。",
        },
        {
          key: 2,
          step: "接口设计",
          content: "设计数据模型，为每个功能设计前后端接口。",
          target: "前后端分离，确定数据结构，保障软件的模块化程度。",
        },
        {
          key: 3,
          step: "优雅设计+",
          content: "对软件的使用界面进行美术设计。",
          target: "精致与优雅是阿半软件的一贯追求。",
        },
        {
          key: 4,
          step: "DevOps",
          content: "微服务开发 → 自动化测试 → 每日发布 → 微服务开发。",
          target: "渐进式的“开发→测试→确认”将全面保障软件质量。",
        },
        {
          key: 5,
          step: "软件上线",
          content: "前后端联合调试，性能测试以及上线试运行。",
          target: "确认软件运行良好后，将软件正式上线。",
        },
      ],
      installGuideData: [
        {
          key: 1,
          content: "第一步：选择软件服务器，自有服务器与云服务器均可。",
        },
        {
          key: 2,
          content: "推荐：使用CentOS操作系统，开源，稳定，免费，主流，易用。",
        },
        { key: 3, content: "第二步：安装数据库。" },
        {
          key: 4,
          content: "推荐：使用MariaDB，开源，稳定，免费，主流，易用。",
        },
        { key: 5, content: "第三步：安装Docker。" },
        {
          key: 6,
          content:
            "说明：为您开发的软件将打包为Docker镜像，强大，免费，主流，易用。",
        },
        { key: 7, content: "第四步：启动Docker 应用容器，开始使用软件。" },
        { key: 8, content: "说明：无需繁琐配置，一键启动，立即使用。" },
      ],
    };
  },
  methods: {
    openContact() {
      this.$router.push({
        path: "/contact",
      });
    },
    openManual() {
      this.$router.push({
        path: "/manual",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .first {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      height: 750px;
      .slogan {
        margin-top: 30px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .minSlogan {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
        }
      }
      .imgGroup {
        display: flex;
        justify-content: center;
        align-items: center;
        .element {
          margin: 70px 20px 25px 20px;
          width: 260px;
          height: 347px;
          border-radius: 3px;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
          .top {
            height: 250px;
          }
          a {
            text-decoration: none;
            outline: none;
            color: #000000;
          }
          .middle {
            height: 60px;
            display: flex;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.75);
            text-indent: 24px;
            cursor: pointer;
            span {
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
      .customertxt {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
          font-weight: 500;
        }
        .link {
          color: #3366ff;
          cursor: pointer;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .second {
    display: flex;
    background-color: #333333;
    .left {
      flex: auto;
    }
    .center {
      background-color: #333333;
      width: 980px;
      min-width: 980px;
      padding-left: 40px;
      padding-right: 40px;
      height: 335px;
      .title {
        margin-top: 30px;
        height: 60px;
        padding-left: 22px;
        span {
          font-size: 36px;
          color: #ffffff;
        }
      }
      .subTitle {
        height: 50px;
        padding-left: 24px;
        margin-bottom: 25px;
        span {
          font-size: 14px;
          color: #ffffff;
        }
      }
      .startDetail {
        height: 40px;
        padding-left: 23px;
        margin-bottom: 10px;
        span {
          font-size: 18px;
          color: #ffffff;
          a {
            color: #ffffff;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .third {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      height: 750px;
      padding-left: 40px;
      padding-right: 40px;
      .title {
        margin-top: 30px;
        height: 60px;
        padding-left: 22px;
        span {
          font-size: 36px;
        }
      }
      .subTitle {
        height: 50px;
        padding-left: 24px;
        margin-bottom: 10px;
        span {
          font-size: 14px;
        }
      }
      .serviceTable {
        border: solid 1px #333333;
        border-radius: 3px;
        .header {
          .hstep {
            width: 100px;
            height: 50px;
            padding-left: 24px;
            span {
              font-weight: 500;
              font-size: 14px;
            }
          }
          .hcontent {
            width: 400px;
            padding-left: 24px;
            span {
              font-weight: 500;
              font-size: 14px;
            }
          }
          .hstandard {
            width: 400px;
            span {
              font-weight: 500;
              font-size: 14px;
            }
          }
        }
        .row {
          .rstep {
            height: 80px;
            padding-left: 24px;
            span {
              font-size: 14px;
              font-weight: 500;
            }
          }
          .rcontent {
            padding-left: 24px;
            padding-right: 24px;
            span {
              font-size: 14px;
            }
          }
          .rstandard {
            padding-right: 24px;
            span {
              font-size: 14px;
            }
          }
        }
      }
      .tabletxt {
        height: 50px;
        padding-left: 24px;
        margin-top: 40px;
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .fourth {
    display: flex;
    background-color: #333333;
    .left {
      flex: auto;
    }
    .center {
      background-color: #333333;
      width: 980px;
      min-width: 980px;
      height: 750px;
      padding-left: 40px;
      padding-right: 40px;
      .title {
        margin-top: 30px;
        height: 60px;
        padding-left: 22px;
        span {
          font-size: 36px;
          color: #ffffff;
        }
      }
      .subTitle {
        height: 50px;
        padding-left: 24px;
        margin-bottom: 10px;
        span {
          font-size: 14px;
          color: #ffffff;
        }
      }
      .serviceTable {
        border: solid 1px #ffffff;
        border-radius: 3px;
        .header {
          .hstep {
            width: 100px;
            height: 50px;
            padding-left: 24px;
            span {
              color: #ffffff;
              font-weight: 500;
              font-size: 14px;
            }
          }
          .hcontent {
            width: 400px;
            padding-left: 24px;
            span {
              color: #ffffff;
              font-weight: 500;
              font-size: 14px;
            }
          }
          .htarget {
            width: 400px;
            span {
              color: #ffffff;
              font-weight: 500;
              font-size: 14px;
            }
          }
        }
        .row {
          .rstep {
            height: 80px;
            padding-left: 24px;
            span {
              color: #ffffff;
              font-size: 14px;
              font-weight: 500;
            }
          }
          .rcontent {
            padding-left: 24px;
            padding-right: 24px;
            span {
              color: #ffffff;
              font-size: 14px;
            }
          }
          .rtarget {
            padding-right: 24px;
            span {
              color: #ffffff;
              font-size: 14px;
            }
          }
        }
      }
      .developtxt {
        margin-top: 40px;
        padding-left: 24px;
        height: 50px;
        span {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .fifth {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      height: 750px;
      padding-left: 40px;
      padding-right: 40px;
      .title {
        margin-top: 30px;
        height: 60px;
        padding-left: 22px;
        span {
          font-size: 36px;
        }
      }
      .subTitle {
        height: 50px;
        padding-left: 24px;
        margin-bottom: 10px;
        span {
          font-size: 14px;
        }
      }
      .installGuide {
        height: 40px;
        padding-left: 23px;
        margin-bottom: 10px;
        span {
          font-size: 18px;
          font-weight: 500;
        }
      }
      .installtxt {
        margin-top: 40px;
        height: 50px;
        padding-left: 24px;
        span {
          font-size: 14px;
          font-weight: 500;
        }
        .link {
          color: #3366ff;
          cursor: pointer;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
}
</style>
