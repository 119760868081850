<template>
  <div class="main">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="productName">
          <span>小程序</span>
        </div>
        <div class="slogan">
          <span>纳须弥于芥子</span>
        </div>
        <div class="price">
          <span>RMB&nbsp;12,800.00&nbsp;起</span>
        </div>
        <div class="imgArea">
          <div class="imgGroupA">
            <div class="img01">
              <img src="img/ProductMinappA.png" alt="" />
            </div>
            <div class="imgGroupAA">
              <div class="img02"></div>
              <div class="img03"></div>
            </div>
          </div>

          <div class="imgGroupB">
            <div class="img04"></div>
            <div class="img05"></div>
          </div>

          <div class="imgGroupC">
            <div class="img06">
              <img src="img/ProductMinappB.png" alt="" />
            </div>
          </div>
        </div>
        <div class="minapptxt">
          <span>十全十美，赏心悦目，只为您的市场销售能转瞬即达。</span>
        </div>
        <div class="minapptxt">
          <span>选择阿半，选择强大。</span>
        </div>
      </div>
      <div class="right"></div>
    </div>

    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div class="priceName">
          <span>服务价目</span>
        </div>
        <div class="priceSlogan">
          <span>选择适合您的服务</span>
        </div>
        <div class="priceTab">
          <div class="element">
            <span :style="tabAColorControl" @click="openInfoPlatform"
              >资讯平台</span
            >
          </div>
          <div class="element"></div>
          <div class="element">
            <span :style="tabBColorControl" @click="openBussinessPlatform"
              >业务平台</span
            >
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <router-view :name="tabContent"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabAColorControl: {
        color: "#3366FF",
        fontWeight: 500,
      },
      tabBColorControl: {},
      tabContent: "infoPlatform",
    };
  },
  methods: {
    openInfoPlatform() {
      this.tabAColorControl = {
        color: "#3366FF",
        fontWeight: 500,
      };
      this.tabBColorControl = {};
      this.tabContent = "infoPlatform";
    },
    openBussinessPlatform() {
      this.tabAColorControl = {};
      this.tabBColorControl = {
        color: "#3366FF",
        fontWeight: 500,
      };
      this.tabContent = "bussinessPlatform";
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .first {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      height: 750px;
      .productName {
        margin-top: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 18px;
        }
      }
      .slogan {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .price {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 18px;
          color: #333333;
        }
      }
      .imgArea {
        display: flex;
        margin: 25px 0px 25px 0px;
        justify-content: center;
        .imgGroupA {
          .img01 {
            width: 400px;
            height: 260px;
            img {
              border-radius: 3px;
              box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.35);
            }
          }
          .imgGroupAA {
            display: flex;
            margin-top: 10px;
            .img02 {
              width: 130px;
              height: 130px;
              background-color: #333333;
              border-radius: 3px;
              box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.35);
            }
            .img03 {
              margin-left: 10px;
              width: 260px;
              height: 130px;
              background-color: #333333;
              border-radius: 3px;
              box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.35);
            }
          }
        }

        .imgGroupB {
          margin-left: 10px;
          .img04 {
            width: 190px;
            height: 260px;
            background-color: #333333;
            border-radius: 3px;
            box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.35);
          }
          .img05 {
            margin-top: 10px;
            width: 190px;
            height: 130px;
            border-radius: 3px;
            box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.35);
          }
        }

        .imgGroupC {
          margin-left: 10px;
          .img06 {
            width: 290px;
            height: 400px;
            img {
              border-radius: 3px;
              box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.35);
            }
          }
        }
      }
      .minapptxt {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .right {
      flex: auto;
    }
  }

  .second {
    display: flex;
    background-color: #f2f2f2;
    .left {
      flex: auto;
    }
    .center {
      background-color: #f2f2f2;
      width: 980px;
      min-width: 980px;
      height: 230px;
      .priceName {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        height: 30px;
        span {
          font-size: 18px;
        }
      }
      .priceSlogan {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .priceTab {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        .element {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 60px;
          border-bottom: solid 1px #333333;
          span {
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
}
</style>
