<template>
  <div class="main">
    <div class="productList">
      <div class="left"></div>
      <div class="center">
        <span id="top"></span>
        <div class="element" @click="openWebsite">
          <div class="elementimg">
            <img src="img/SmallWebsite.png" alt="" />
          </div>
          <div class="elementtxt">
            <span>网站</span>
          </div>
        </div>
        <div class="element" @click="openMinapp">
          <div class="elementimg">
            <img src="img/SmallMinApp.png" alt="" />
          </div>
          <div class="elementtxt">
            <span>小程序</span>
          </div>
        </div>
        <div class="element" @click="openSystem">
          <div class="elementimg">
            <img src="img/SmallSystem.png" alt="" />
          </div>
          <div class="elementtxt">
            <span>企业系统</span>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="spliter">
      <div class="left"></div>
      <div class="center"></div>
      <div class="right"></div>
    </div>
    <router-view :name="product"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    openWebsite() {
      this.$router.push({
        path: "/product",
        query: {
          product: "website",
        },
      });
    },
    openMinapp() {
      this.$router.push({
        path: "/product",
        query: {
          product: "minapp",
        },
      });
    },
    openSystem() {
      this.$router.push({
        path: "/product",
        query: {
          product: "system",
        },
      });
    },
  },
  computed: {
    product: {
      get() {
        return this.$route.query.product
          ? this.$route.query.product
          : "website";
      },
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .productList {
    display: flex;
    height: 116px;
    .left {
      flex: auto;
    }

    .center {
      width: 980px;
      min-width: 980px;
      display: flex;
      justify-content: center;
      align-items: center;
      .element {
        margin: 20px;
        cursor: pointer;
        .elementimg {
          width: 80px;
          height: 40px;
          img {
            box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.5);
          }
        }
        .elementtxt {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          span {
            font-size: 10px;
          }
        }
      }
    }

    .right {
      flex: auto;
    }
  }
  .spliter {
    height: 34px;
    background-color: #3366ff;
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      height: 34px;
      background-color: #3366ff;
    }
    .right {
      flex: auto;
    }
  }
}
</style>
