<template>
  <div class="main">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>细致开发，不止于好</span>
        </div>
        <div class="minSlogan">
          <span>需要服务？点击此处</span>
          <span class="spanlink" @click="toContact">&nbsp;联系我们</span>
        </div>
        <div class="home01">
          <img src="img/Home01.png" />
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>产品优雅，服务卓越</span>
        </div>
        <div class="minSlogan">
          <span>阿半软件能为您提供</span>
        </div>
        <div class="home02">
          <div class="groupA">
            <div class="website">
              <img src="img/HomeWebsite.png" alt="" />
            </div>
            <div class="minapp">
              <img src="img/HomeMinapp.png" alt="" />
            </div>
          </div>
          <div class="groupB">
            <div class="system">
              <img src="img/HomeSystem.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="third">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>技术立身，值得信赖</span>
        </div>
        <div class="minSlogan">
          <span>绝不辜负您的信任</span>
        </div>
        <div class="home03">
          <div class="groupA">
            <div class="ele01"><span>7×24服务</span></div>
            <div class="ele02"><span>按时交付</span></div>
            <div class="ele01"><span>质量保障</span></div>
            <div class="ele02"><span>过程透明</span></div>
          </div>
          <div class="groupB">
            <div class="ele03"></div>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toContact() {
      this.$router
        .push({
          path: "/contact",
        })
        .catch((err) => err);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .first {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      .slogan {
        margin-top: 30px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .minSlogan {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
        }
        .spanlink {
          color: #0000cc;
          cursor: pointer;
        }
      }
      .home01 {
        height: 500px;
        width: 980px;
        margin-top: 90px;
        margin-bottom: 15px;
        img {
          border-radius: 3px;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .second {
    display: flex;
    background-color: #333333;
    height: 750px;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      background-color: #333333;
      .slogan {
        margin-top: 30px;
        display: flex;
        height: 60px;
        align-items: center;
        justify-content: center;
        span {
          color: #ffffff;
          font-size: 48px;
          font-weight: 500;
        }
      }
      .minSlogan {
        display: flex;
        height: 50px;
        justify-content: center;
        align-items: center;
        span {
          color: #ffffff;
          font-size: 14px;
        }
      }
      .home02 {
        margin-top: 90px;
        .groupA {
          display: flex;
          justify-content: center;
          align-items: center;
          .website {
            width: 400px;
            height: 200px;
          }
          .minapp {
            width: 400px;
            height: 200px;
          }
        }
        .groupB {
          display: flex;
          justify-content: center;
          align-items: center;
          .system {
            width: 400px;
            height: 200px;
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .third {
    display: flex;
    height: 750px;
    .left {
      flex: auto;
    }

    .center {
      width: 980px;
      min-width: 980px;
      .slogan {
        margin-top: 30px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .minSlogan {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
        }
      }
      .home03 {
        margin-top: 90px;
        .groupA {
          display: flex;
          justify-content: center;
          align-items: center;
          .ele01 {
            width: 190px;
            height: 190px;
            border-radius: 10px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px;
            span {
              font-size: 28px;
              color: #333333;
            }
          }
          .ele02 {
            margin: 10px;
            width: 190px;
            height: 190px;
            background-color: #333333;
            border-radius: 10px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              color: #ffffff;
              font-size: 28px;
            }
          }
        }
        .groupB {
          display: flex;
          justify-content: center;
          align-items: center;
          .ele03 {
            background-color: #333333;
            margin-top: 10px;
            width: 820px;
            height: 190px;
            border-radius: 10px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
          }
        }
      }
    }

    .right {
      flex: auto;
    }
  }
}
</style>
