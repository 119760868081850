<template>
  <div class="main">
    <div class="navigator">
      <div class="left"></div>
      <div class="center">
        <router-link to="home" custom v-slot="{ navigate }">
          <img class="logo" @click="navigate" src="img/LOGO.svg" alt="" />
        </router-link>
        <router-link to="home" custom v-slot="{ navigate }">
          <span @click="navigate">首页</span>
        </router-link>
        <router-link to="product" custom v-slot="{ navigate }">
          <span @click="navigate">产品与服务</span>
        </router-link>
        <router-link to="customer" custom v-slot="{ navigate }">
          <span @click="navigate">客户指引</span>
        </router-link>
        <router-link to="service" custom v-slot="{ navigate }">
          <span @click="navigate">服务承诺</span>
        </router-link>
        <router-link to="manual" custom v-slot="{ navigate }">
          <span @click="navigate">服务介绍</span>
        </router-link>
        <router-link to="motel" custom v-slot="{ navigate }">
          <span @click="navigate">阿半客栈</span>
        </router-link>
        <router-link to="about" custom v-slot="{ navigate }">
          <span @click="navigate">关于阿半</span>
        </router-link>
        <router-link to="contact" custom v-slot="{ navigate }">
          <span @click="navigate">联系我们</span>
        </router-link>
      </div>
      <div class="right"></div>
    </div>
    <router-view></router-view>
    <div class="sitemap">
      <div class="left"></div>
      <div class="center">
        <div class="mapcontent">
          <div class="group01">
            <div class="title"><span @click="openHomePage">首页</span></div>
            <div class="menu"><span @click="openHomePage">阿半软件</span></div>
          </div>
          <div class="group02">
            <div class="title">
              <span @click="openProduct">产品与服务</span>
            </div>
            <div class="menu">
              <span @click="openProductWebsite">网站</span>
            </div>
            <div class="menu">
              <span @click="openProductMinapp">小程序</span>
            </div>
            <div class="menu">
              <span @click="openProductSystem">企业系统</span>
            </div>
          </div>
          <div class="group03">
            <div class="title"><span @click="openCustomer">客户指引</span></div>
            <div class="menu">
              <span @click="openCustomerStart">项目启动</span>
            </div>
            <div class="menu">
              <span @click="openCustomerDevelop">项目过程</span>
            </div>
            <div class="menu">
              <span @click="openCustomerInstall">使用指南</span>
            </div>
          </div>
          <div class="group04">
            <div class="title"><span @click="openService">服务承诺</span></div>
            <div class="menu">
              <span @click="openServiceStandard">服务标准</span>
            </div>
            <div class="menu">
              <span @click="openManual">服务介绍</span>
            </div>
            <div class="title"><span @click="openMotel">阿半客栈</span></div>
            <div class="menu"><span @click="openMotel">阿半资讯</span></div>
          </div>
          <div class="group05">
            <div class="title"><span @click="openAbout">关于阿半</span></div>
            <div class="menu">
              <span @click="openAbout">阿半的前世今生</span>
            </div>
            <div class="title"><span @click="openContact">联系我们</span></div>
            <div class="menu"><span @click="openContact">客户留言</span></div>
          </div>
        </div>
        <div class="footer">
          <div class="jianjie">
            <span
              >Copyright@2021-2022 阿半软件（上海）有限公司 保留所有权利</span
            >
          </div>
          <div class="icp">
            <a href="https://beian.miit.gov.cn">
              <span>沪ICP备2021033386号-1</span>
            </a>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    openHomePage() {
      this.$router.push({
        path: "/home",
      });
      window.scrollTo(0, 0);
    },
    openProduct() {
      this.$router.push({
        path: "/product",
      });
      window.scrollTo(0, 0);
    },
    openProductWebsite() {
      this.$router.push({
        path: "/product",
        query: {
          product: "website",
        },
      });
      window.scrollTo(0, 0);
    },
    openProductMinapp() {
      this.$router.push({
        path: "/product",
        query: {
          product: "minapp",
        },
      });
      window.scrollTo(0, 0);
    },
    openProductSystem() {
      this.$router.push({
        path: "/product",
        query: {
          product: "system",
        },
      });
      window.scrollTo(0, 0);
    },
    openCustomer() {
      this.$router.push({
        path: "/customer",
      });
      window.scrollTo(0, 0);
    },
    openCustomerStart() {
      this.$router.push({
        path: "/customer",
      });
      window.scrollTo(0, 800);
    },
    openCustomerDevelop() {
      this.$router.push({
        path: "/customer",
      });
      window.scrollTo(0, 1880);
    },
    openCustomerInstall() {
      this.$router.push({
        path: "/customer",
      });
      window.scrollTo(0, 2630);
    },
    openService() {
      this.$router.push({
        path: "/service",
      });
      window.scrollTo(0, 0);
    },
    openServiceStandard() {
      this.$router.push({
        path: "/service",
      });
      window.scrollTo(0, 800);
    },
    openManual() {
      this.$router.push({
        path: "/manual",
      });
    },
    openMotel() {
      this.$router.push({
        path: "/motel",
      });
      window.scrollTo(0, 0);
    },
    openAbout() {
      this.$router.push({
        path: "/about",
      });
      window.scrollTo(0, 0);
    },
    openContact() {
      this.$router.push({
        path: "/contact",
      });
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="less">
.main {
  .navigator {
    background-color: #000000;
    display: flex;
    height: 44px;
    .left {
      flex: auto;
    }
    .center {
      display: flex;
      background-color: #000000;
      width: 980px;
      min-width: 980px;
      align-items: center;
      justify-content: space-between;
      .logo {
        margin-right: 60px;
        width: 80px;
        height: 30px;
        cursor: pointer;
      }
      span {
        margin-right: 50px;
        color: #ffffff;
        font-size: 10px;
        cursor: pointer;
      }
    }
    .right {
      flex: auto;
    }
  }
  .sitemap {
    display: flex;
    background-color: #f2f2f2;
    .left {
      flex: auto;
    }

    .center {
      width: 980px;
      min-width: 980px;
      background-color: #f2f2f2;
      .mapcontent {
        display: flex;
        justify-content: center;
        height: 300px;
        border-bottom: solid 1px #d7d7d7;
        .group01 {
          width: 200px;
          span {
            cursor: pointer;
          }
        }
        .group02 {
          width: 200px;
          span {
            cursor: pointer;
          }
        }
        .group03 {
          width: 200px;
          span {
            cursor: pointer;
          }
        }
        .group04 {
          width: 200px;
          span {
            cursor: pointer;
          }
        }
        .group05 {
          span {
            cursor: pointer;
          }
        }
        .title {
          margin-top: 30px;
          span {
            font-weight: 500;
            font-size: 12px;
            color: #333333;
          }
        }
        .menu {
          margin-top: 5px;
          span {
            font-size: 12px;
            color: #333333;
          }
        }
      }
      .footer {
        display: flex;
        height: 40px;
        margin-bottom: 40px;
        justify-content: space-between;
        align-items: center;
        .jianjie {
          span {
            font-size: 12px;
            color: #333333;
          }
        }
        .icp {
          span {
            font-size: 12px;
            color: #333333;
          }
        }
      }
    }

    .right {
      flex: auto;
    }
  }
}
</style>
