<template>
  <div class="main">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>精致与优雅是我们的承诺</span>
        </div>
        <div class="subSlogan">
          <span>采购软件更简单</span>
        </div>
        <div class="serviceImg">
          <img src="img/Service.png" alt="" />
        </div>
        <div class="servicetxt">
          <span>让您购买时放心，开发时省心，使用时称心。</span>
        </div>
        <div class="servicetxt">
          <span>选择阿半，事事顺心。</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>阿半值得信赖</span>
        </div>
        <div class="subSlogan">
          <span>我们将以下面的标准为您提供服务</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="third">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>服务标准</span>
        </div>
        <div
          class="standardDetail"
          v-for="item in standardDetailData"
          v-bind:key="item.key"
        >
          <div class="standard">
            <span>{{ item.standard }}</span>
          </div>
          <div class="txt">
            <span>{{ item.txt }}</span>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      standardDetailData: [
        {
          key: 1,
          standard: "及时反馈",
          txt: "7 × 24 小时服务，全天候运行保障",
        },
        { key: 2, standard: "准时交付", txt: "在约定的日期，交付高质量的产品" },
        {
          key: 3,
          standard: "配套完备",
          txt: "您将得到完整的产品配套文档，描述准确，通俗易懂",
        },
        {
          key: 4,
          standard: "全程透明",
          txt: "您可以随时在监督平台上检查项目的开发进度",
        },
        {
          key: 5,
          standard: "安全合规",
          txt: "全程合法合规，不为您带来任何商业风险",
        },
        {
          key: 6,
          standard: "满意保障",
          txt: "产品上线 15 日内不满意，我们将为您免费升级一次",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.main {
  .first {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      height: 750px;
      .slogan {
        margin-top: 30px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .subSlogan {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
        }
      }
      .serviceImg {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 25px;
        img {
          border-radius: 3px;
          box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.5);
        }
      }
      .servicetxt {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .second {
    display: flex;
    background-color: #333333;
    .left {
      flex: auto;
    }
    .center {
      background-color: #333333;
      width: 980px;
      min-width: 980px;
      height: 170px;
      .slogan {
        margin-top: 30px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-weight: 500;
          font-size: 48px;
          color: #ffffff;
        }
      }
      .subSlogan {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .third {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      height: 750px;
      .slogan {
        margin-top: 30px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 90px;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .standardDetail {
        display: flex;
        justify-content: center;
        align-items: center;
        .standard {
          height: 60px;
          width: 300px;
          display: flex;
          justify-content: flex-start;
          span {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .txt {
          height: 60px;
          width: 400px;
          display: flex;
          justify-content: flex-end;
          span {
            font-size: 14px;
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
}
</style>
