<template>
  <div class="main">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="productName">
          <span>网站建设</span>
        </div>
        <div class="slogan">
          <span>尽展云端艺术</span>
        </div>
        <div class="price">
          <span>RMB&nbsp;9,800.00&nbsp;起</span>
        </div>
        <div class="websiteimg">
          <img src="img/ProductWebsite.png" alt="" />
        </div>
        <div class="websitetxt">
          <span> 匠心独运，一丝不苟，只为您的品牌宣传能独占鳌头。</span>
        </div>
        <div class="websitetxt">
          <span> 选择阿半，选择优雅。 </span>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div class="priceName">
          <span>服务价目</span>
        </div>
        <div class="priceSlogan">
          <span>选择适合您的服务</span>
        </div>
        <div class="priceTab">
          <div class="element">
            <span :style="tabAColorControl" @click="openBrandPromotion"
              >品牌宣传</span
            >
          </div>
          <div class="element"></div>
          <div class="element">
            <span :style="tabBColorControl" @click="openInfoPortal"
              >信息门户</span
            >
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <router-view :name="tabContent"></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabAColorControl: {
        color: "#3366FF",
        fontWeight: 500,
      },
      tabBColorControl: {},
      tabContent: "brandPromotion",
    };
  },
  methods: {
    openBrandPromotion() {
      this.tabBColorControl = {};
      this.tabAColorControl = {
        color: "#3366FF",
        fontWeight: 500,
      };
      this.tabContent = "brandPromotion";
    },
    openInfoPortal() {
      this.tabAColorControl = {};
      this.tabBColorControl = {
        color: "#3366FF",
        fontWeight: 500,
      };
      this.tabContent = "infoPortal";
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  .first {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      height: 750px;
      .productName {
        height: 30px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 18px;
        }
      }
      .slogan {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .price {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 18px;
          color: #333333;
        }
      }
      .websiteimg {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        margin-bottom: 25px;
        img {
          border-radius: 3px;
          box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.5);
        }
      }
      .websitetxt {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .second {
    display: flex;
    background-color: #f2f2f2;
    .left {
      flex: auto;
    }
    .center {
      background-color: #f2f2f2;
      width: 980px;
      min-width: 980px;
      height: 230px;
      .priceName {
        margin-top: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 18px;
        }
      }
      .priceSlogan {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .priceTab {
        display: flex;
        margin-top: 60px;
        justify-content: center;
        .element {
          border-bottom: solid 1px #333333;
          height: 40px;
          display: flex;
          width: 60px;
          justify-content: center;
          align-items: center;
          span {
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
}
</style>
