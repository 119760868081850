<template>
  <div class="main">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>服务介绍</span>
        </div>
        <div class="subSlogan">
          <span>您可以在此详细了解阿半软件所提供的服务</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div v-html="manualMarkdown" class="markdown"></div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
import { marked } from "marked";
export default {
  data() {
    return {
      manualMarkdown: "",
    };
  },
  created() {
    this.renderManualMarkdown();
  },
  methods: {
    renderManualMarkdown() {
      this.$axios({
        method: "get",
        url: "/document/Manual.md",
      })
        .then((res) => {
          this.manualMarkdown = marked(res.data);
        })
        .catch(() => {
          this.manualMarkdown = marked("### 正在获取信息，请稍候...");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  .first {
    display: flex;
    background-color: #333333;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      background-color: #333333;
      .slogan {
        width: 980px;
        margin-top: 30px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .subSlogan {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        span {
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .second {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      padding-top: 90px;
      width: 980px;
      min-width: 980px;
    }
    .right {
      flex: auto;
    }
  }
}
</style>
