<template>
  <div class="mian">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>红尘繁冗，且饮一盏再行</span>
        </div>
        <div class="subSlogan">
          <span>畅所欲言趁微醺，浊酒一杯遥祝君</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>客栈正在筹备，敬请期待...</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.main {
  .first {
    display: flex;
    background-color: #333333;
    .left {
      flex: auto;
    }
    .center {
      background-color: #333333;
      width: 980px;
      min-width: 980px;
      height: 170px;
      .slogan {
        height: 60px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .subSlogan {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .second {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      .slogan {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 36px;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
}
</style>
