<template>
  <div class="main">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>联系我们</span>
        </div>

        <div class="subSlogan">
          <span>您可以直接在此留言，我们会与您取得联系</span>
        </div>
        <div class="contactArea">
          <div class="contactForm">
            <div class="contactType">
              <div class="name">
                <span>咨询业务</span>
              </div>
              <div class="checkboxGroup">
                <div
                  class="checkbox"
                  v-for="item in checkboxGroupData"
                  v-bind:key="item.key"
                >
                  <input type="checkbox" :id="item.id" :value="item.value" />
                  <label :for="item.id">{{ item.label }}</label>
                </div>
              </div>
            </div>
            <div class="phone">
              <div class="name">
                <span>联系电话</span>
              </div>
              <div class="phonetxt">
                <input type="text" id="phone" />
              </div>
            </div>
            <div class="button">
              <button><span>立即留言</span></button>
            </div>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>您也可以通过以下渠道联系我们</span>
        </div>
        <div
          class="contactGroup"
          v-for="item in contactGroupData"
          v-bind:key="item.key"
        >
          <div class="channel">
            <span>{{ item.channel }}</span>
          </div>

          <div class="txt" v-if="item.key === 3">
            <span>
              <a href="http://bankezhan.taobao.com" target="_blank">{{
                item.txt
              }}</a>
            </span>
          </div>

          <div class="txt" v-else>
            <span>{{ item.txt }}</span>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkboxGroupData: [
        { key: 1, id: "website", value: "website", label: "网站建设" },
        { key: 2, id: "minapp", value: "minapp", label: "小程序开发" },
        { key: 3, id: "system", value: "system", label: "企业系统" },
      ],
      contactGroupData: [
        { key: 1, channel: "客户经理", txt: "18964107890  田先生" },
        { key: 2, channel: "客服QQ", txt: "2557923989  阿半软件" },
        { key: 3, channel: "淘宝旗舰店", txt: "bankezhan.taobao.com 阿半软件" },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.main {
  .first {
    display: flex;
    background-color: #333333;
    .left {
      flex: auto;
    }
    .center {
      background-color: #333333;
      width: 980px;
      min-width: 980px;
      height: 450px;
      .slogan {
        height: 60px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          color: #ffffff;
          font-size: 48px;
          font-weight: 500;
        }
      }
      .subSlogan {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        span {
          color: #ffffff;
          font-size: 14px;
        }
      }
      .contactArea {
        display: flex;
        justify-content: center;
        .contactForm {
          width: 600px;
          height: 225px;
          background-color: #ffffff;
          border-radius: 3px;
          box-shadow: 0px 0px 50px 0px rgba(255, 255, 255, 0.5);
          .contactType {
            display: flex;
            margin-top: 10px;
            margin-left: 40px;
            .name {
              width: 80px;
              height: 60px;
              display: flex;
              align-items: center;
              span {
                font-size: 14px;
                font-weight: 500;
              }
            }
            .checkboxGroup {
              display: flex;
              margin-left: 30px;
              .checkbox {
                width: 130px;
                display: flex;
                align-items: center;
                label {
                  font-size: 12px;
                  margin-left: 10px;
                  cursor: pointer;
                }
              }
            }
          }
          .phone {
            display: flex;
            margin-left: 40px;
            width: 500px;
            border-bottom: solid 1px #333333;
            .name {
              width: 80px;
              height: 60px;
              display: flex;
              align-items: center;
              span {
                font-size: 14px;
                font-weight: 500;
              }
            }
            .phonetxt {
              margin-left: 30px;
              display: flex;
              align-items: center;
              input {
                width: 390px;
                height: 60px;
                border: none;
              }
            }
          }
          .button {
            margin-top: 20px;
            display: flex;
            height: 60px;
            justify-content: center;
            align-items: center;
            button {
              width: 300px;
              height: 50px;
              background-color: #333333;
              border: none;
              span {
                font-size: 18px;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .second {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      height: 300px;
      .slogan {
        height: 30px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        span {
          font-size: 14px;
        }
      }
      .contactGroup {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        .channel {
          width: 100px;
          display: flex;
          justify-content: flex-start;
          span {
            font-size: 14px;
            font-weight: 500;
          }
        }
        .txt {
          width: 300px;
          display: flex;
          justify-content: flex-end;
          span {
            font-size: 14px;
            a {
              color: #000000;
              text-decoration: none;
              cursor: pointer;
            }
          }
        }
      }
    }
    .right {
      flex: auto;
    }
  }
}
</style>
