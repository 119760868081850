<template>
  <div class="main">
    <div class="first">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>阿半软件的诞生</span>
        </div>
        <div class="subSlogan">
          <span>一个工程师靠技术养家糊口的朴素愿望</span>
        </div>

        <div class="abouttxt">
          <span
            >阿半软件（上海）有限公司于2021年在上海成立，创始人有八年金融行业软件研发经验，基于靠技术能力养家糊口的朴素愿望，开办了阿半软件。</span
          >
        </div>

        <div class="abouttxt">
          <span
            >阿半软件主营网站建设、小程序开发与企业软件开发等三类业务，秉承着“靠质量赢得客户信赖”的经营理念，为客户打造软件购买的极致体验：议价简单放心，开发透明省心，成品优雅称心。</span
          >
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="second">
      <div class="left"></div>
      <div class="center">
        <div class="slogan">
          <span>发展愿景</span>
        </div>
        <div class="subSlogan">
          <span>穷则独善其身，达则兼济天下</span>
        </div>
        <div class="wishtxt" v-for="item in wishtxtData" v-bind:key="item.key">
          <span>{{ item.txt }}</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wishtxtData: [
        {
          key: 1,
          txt: "阿半软件希望能够为奋斗者们提供个人财务规划与保障相关的知识与工具，防范消费陷阱，对抗资本剥削，保卫劳动果实。",
        },
        { key: 2, txt: " 阿半客栈由此而生。" },
        { key: 3, txt: "我们希望，在阿半客栈的帮助下：" },
        { key: 4, txt: "年轻人不再落入贷款陷阱，" },
        { key: 5, txt: "奋斗者不再为他人做嫁衣，" },
        { key: 6, txt: "辛勤付出终得善果，" },
        { key: 7, txt: "努力追梦终得实现。" },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.main {
  .first {
    display: flex;
    .left {
      flex: auto;
    }
    .center {
      width: 980px;
      min-width: 980px;
      height: 500px;
      padding-left: 40px;
      padding-right: 40px;
      .slogan {
        margin-top: 30px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
        }
      }
      .subSlogan {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        span {
          font-size: 14px;
        }
      }
      .abouttxt {
        text-indent: 36px;
        line-height: 36px;
        margin-bottom: 20px;
        span {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
  .second {
    display: flex;
    background-color: #333333;
    .left {
      flex: auto;
    }
    .center {
      background-color: #333333;
      width: 980px;
      min-width: 980px;
      padding-left: 40px;
      padding-right: 40px;
      height: 750px;
      .slogan {
        margin-top: 30px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 48px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .subSlogan {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        span {
          font-size: 14px;
          color: #ffffff;
        }
      }
      .wishtxt {
        text-indent: 36px;
        line-height: 36px;
        margin-bottom: 20px;
        span {
          color: #ffffff;
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
    .right {
      flex: auto;
    }
  }
}
</style>
