import { render, staticRenderFns } from "./Website.vue?vue&type=template&id=53454596&scoped=true&"
import script from "./Website.vue?vue&type=script&lang=js&"
export * from "./Website.vue?vue&type=script&lang=js&"
import style0 from "./Website.vue?vue&type=style&index=0&id=53454596&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53454596",
  null
  
)

export default component.exports